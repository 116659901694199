import { makePostResquest, makeGetRequest } from 'app/apis/server';

const TYPES = {
  admin: 1,
  normal: 2
};

const isNormal = type => Number(type) === TYPES.normal;
const isAdmin = type => Number(type) === TYPES.admin;

const signIn = data => (
  makePostResquest({ route: 'user/sign-in', data })
);

const signUp = data => (
  makePostResquest({ route: 'user/sign-up', ...data })
);

const get = authorizationToken => makeGetRequest({ route: 'user/get', authorizationToken })

const sendRecoveryPasswordToken = data => (
  makePostResquest({ route: 'user/send-recovery-password-token', data })
);

const validateRecoveryPasswordToken = data => (
  makePostResquest({ route: `user/validate-recovery-password-token`, data })
);

const changePassword = data => (
  makePostResquest({ route: 'user/change-password', ...data })
);

const listAll = (authorizationToken, type) => (
  makeGetRequest({ route: `user/list-all/${type}`, authorizationToken })
);

const search = (authorizationToken, query) => (
  makeGetRequest({ route: `user/search/${query}`, authorizationToken })
);

const enable = (id, authorizationToken) => (
  makeGetRequest({ route: `user/enable/${id}`, authorizationToken })
);

const disable = (id, authorizationToken) => (
  makeGetRequest({ route: `user/disable/${id}`, authorizationToken })
);

export default {
  TYPES,
  isAdmin,
  isNormal,
  signIn,
  signUp,
  get,
  listAll,
  search,
  sendRecoveryPasswordToken,
  validateRecoveryPasswordToken,
  changePassword,
  enable,
  disable
};
