import { Link as LinkLib } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    padding: 10px;
  }
`;

export const Form = styled.form`
  margin: 50px 0px 50px 0px;
  width: 600px;

  @media only screen and (max-width: 600px) {
    margin: 20px 0px 10px 0px;
  }
`;

export const Link = styled(LinkLib)`
  margin-left: 20px;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
`;

export const SplitFieldContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;

  @media only screen and (max-width: 600px) {
    grid-template-columns: auto;
  }
`;
