import jwtDecode from 'jwt-decode';
import {
  setSessionToken, getSessionToken, removeSessionToken
} from 'app/utils/session';
import Model from '../model';

export const signIn = ({ email, password }) => (
  Model.signIn({ email, password }).then(
    ({ authorizationToken }) => startSession(authorizationToken)
  )
);

export const signUp = data => (
  Model.signUp({ data, authorizationToken: getSessionToken() })
);

export const get = () => Model.get(getSessionToken());

export const listAll = type => Model.listAll(getSessionToken(), type);

export const search = query => Model.search(getSessionToken(), query);

export const sendRecoveryPasswordToken = data => (
  Model.sendRecoveryPasswordToken({
    ...data, baseUrl: `${process.env.REACT_APP_PUBLIC_URL}/usuario/validar-token-recuperacao-senha`
  })
);

export const validateRecoveryPasswordToken = data => (
  Model.validateRecoveryPasswordToken(data)
);

export const changePassword = data => (
  Model.changePassword({ data, authorizationToken: getSessionToken() })
);

export const startSession = authorizationToken => {
  setSessionToken(authorizationToken);

  return {
    authorizationToken,
    ...jwtDecode(authorizationToken).sub
  };
};

export const getSession = () => (
  new Promise((resolve, reject) => {
    const authorizationToken = getSessionToken();

    if(authorizationToken && authorizationToken !== 'null') {
      resolve(authorizationToken);

      return {
        authorizationToken,
        ...jwtDecode(authorizationToken).sub
      };
    } else {
      reject();
    }
  })
);

export const signout = () => {
  removeSessionToken();
};

export const getSessionPayload = () => (
  jwtDecode(getSessionToken())
);

export const enable = id => Model.enable(id, getSessionToken());
export const disable = id => Model.disable(id, getSessionToken());