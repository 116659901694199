import shared from './shared';
import home from './home';
import contact from './contact';
import user from './user';
import process from './process';
import article from './article';
import legalCase from './case';
import comment from './comment';

export {
  shared,
  home,
  contact,
  user,
  process,
  article,
  legalCase,
  comment
};
