import { makePostResquest, makeGetRequest, makeDeleteRequest } from 'app/apis/server';

const STATUS = {
  unpublished: 0,
  published: 1
};

const save = data => makePostResquest({ route: 'article/register', ...data });
const list = authorizationToken => makeGetRequest({ route: 'article/list-all', authorizationToken });
const getById = (authorizationToken, id) => makeGetRequest({ route: `article/get/${id}`, authorizationToken });
const deleteById = (authorizationToken, id) => makeDeleteRequest({ route: `article/${id}`, authorizationToken });

export default {
  STATUS,
  save,
  list,
  getById,
  deleteById
};
