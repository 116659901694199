import React from 'react';
import { Spin, notification } from 'antd';
import i18next from 'i18next';
import { get } from 'lodash';
import { validateRecoveryPasswordToken, startSession } from 'app/modules/user/actions';
import { Container } from './styles';

export default ({ history, routeParams }) => {
  validateRecoveryPasswordToken({ token: routeParams.token }).then(({ success, authorizationToken }) => {
    if(success && authorizationToken) {
      startSession(authorizationToken);
      history.push('/usuario/mudar-senha');
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    notification.open({
      message: i18next.t('user.validateRecoveryPassword.failMessage.title'),
      description: get(error, 'response.data.message', i18next.t('user.validateRecoveryPassword.failMessage.message'))
    });
  });

  return <Container><Spin size="large" /></Container>;
};
