import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, get, pick } from 'lodash';
import { Popconfirm, Icon, Modal, Button, notification } from 'antd';
import { Formik, Field } from 'formik';
import { getSessionPayload } from 'app/modules/user/actions';
import { listByRegisterId, save, deleteById } from 'app/modules/comment/actions';
import { updateCommentsCounter } from 'app/modules/case/actions';
import Input from 'app/components/input';
import validate from './validate';
import { ListContainer, CommentContainer } from './styles';

const propTypes = {
  registerId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool
};

const defaultProps = {
  visible: false
};

function List({ visible, registerId, ...otherProps }) {
  const { id: userSessionId } = getSessionPayload();
  const dispatch = useDispatch();
  const list = useSelector(state => state.comment.list);

  useEffect(() => {
    if(visible) {
      listByRegisterId(registerId)(dispatch);
    }
  }, [visible, registerId, dispatch]);

  function onSubmit(data, actions) {
    const newData = pick(data, ['id', 'userId', 'registerId', 'content']);

    save(newData)(dispatch).then(() => {
      updateCommentsCounter({ id: registerId, operation: 'add' })(dispatch);
      actions.resetForm({ content: '', registerId });
    }).catch(error => {
      actions.setSubmitting(false);

      notification.open({
        message: i18next.t('comment.error.title'),
        description: get(error, 'response.data.message', i18next.t('comment.error.message'))
      });
    });
  }

  function onDelete(id) {
    return () => deleteById(id)(dispatch).then(() => {
      updateCommentsCounter({ id: registerId, operation: 'remove' })(dispatch);
    });
  }

  function renderForm({ handleSubmit, isSubmitting, touched, errors }) {
    return (
      <form onSubmit={handleSubmit}>
        <Field
          component={Input}
          label={i18next.t('comment.fields.content')}
          error={touched.content && errors.content}
          name="content"
          type="textarea"
        />

        <Button
          type="primary"
          size="large"
          htmlType="submit"
          disabled={!isEmpty(errors) || isEmpty(touched) || isSubmitting}
          loading={isSubmitting}
        >
          {i18next.t('shared.actions.save')}
        </Button>
      </form>
    );
  }

  function renderItemActions(item) {
    if(item.userId === userSessionId) {
      return [
        <Popconfirm
          title={i18next.t('legalCase.list.deleteMessage')}
          icon={<Icon type="exclamation-circle" />}
          okText={i18next.t('shared.decisions.yes')}
          cancelText={i18next.t('shared.decisions.no')}
          onConfirm={onDelete(item.id)}
        >
          <Icon type='delete'>{i18next.t('shared.actions.delete')}</Icon>
        </Popconfirm>
      ];
    }

    return [];
  }

  return (
    <Modal
      {...otherProps}
      visible={visible}
      title={i18next.t('comment.title')}
      footer={null}
    >
      <Formik
        enableReinitialize
        initialValues={{ registerId }}
        onSubmit={onSubmit}
        render={renderForm}
        validate={validate}
      />

      <ListContainer>
        {
          list.map(item => (
            <CommentContainer
              key={item.id}
              author={<span>{item.User.name}</span>}
              content={<p>{item.content}</p>}
              actions={renderItemActions(item)}
            />
          ))
        }
      </ListContainer>
    </Modal>
  );
};

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default memo(List);
