import styled from 'styled-components';

export const Container = styled.div.attrs(props => ({
  type: props.type
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${props => props.type === 'textarea' ? 'auto' : '95px'};
`;
