import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-BR');

export const formatDate = (date, oldFormat = 'YYYY-MM-DDTHH:mm:ssZ', showHour = false) => (
  moment(date, oldFormat).format(showHour ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY')
);

export const formatToDate = (date, oldFormat = 'DD/MM/YYYY') => moment(date, oldFormat).toDate();
export const timeAgo = date => moment(date).fromNow();
export const getStartMonth = (date = new Date()) => moment(date).startOf('month');
export const getEndMonth = (date = new Date()) => moment(date).endOf('month');
export const convertDateToMomentObject = date => moment(date);
export const convertDateToNumber = date => +moment(date);
export const isSameDates = (dateA, dateB, measure) => moment(dateA).isSame(moment(dateB), measure);
