export default {
  fields: {
    createdAt: 'Criado em',
    clientName: 'Cliente',
    title: 'Título',
    status: 'Status',
    client: 'Nome do cliente',
    observations: 'Observações',
    statusOptions: {
      started: 'Iniciado',
      open: 'Aberto',
      closed: 'Finalizado'
    }
  },

  list: {
    title: 'Lista de processos',
    actions: 'Ações',
    addProcess: 'Adicionar processo'
  },

  register: {
    title: 'Cadastro de processo'
  }
};
