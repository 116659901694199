import { combineReducers } from 'redux';
import { COMMENT_ADDED, COMMENT_EDITED, COMMENT_LIST, COMMENT_DELETED } from 'app/modules/comment/actions';

function list(state = [], action = {}) {
  switch(action.type) {
    case COMMENT_LIST:
      return action.payload;
    case COMMENT_ADDED:
      return [action.payload, ...state];
    case COMMENT_EDITED:
      return state.map(item => {
        if(item.id === action.payload.id) {
          return action.payload;
        }

        return item;
      });
    case COMMENT_DELETED:
      return state.filter(item => item.id !== action.payload);
    default:
      return state;
  }
}

export default combineReducers({
  list
});
