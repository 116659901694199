import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { isEmpty, get, pick } from 'lodash';
import { Modal, Button, notification } from 'antd';
import { Formik, Field } from 'formik';
import Input from 'app/components/input';
import Select from 'app/components/select';
import RichText from 'app/components/richText';
import Model from 'app/modules/article/model';
// import { upload } from 'app/modules/file/actions';
import { save } from 'app/modules/article/actions';
import validate from './validate';
import { ButtonContainer } from './styles';
import { upload } from 'app/modules/file/actions';

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmitFinish: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    status: PropTypes.number,
    content: PropTypes.string
  })
};

const defaultProps = {
  visible: false,
  data: {}
};

function Register({ history, data, onSubmitFinish, ...otherProps }) {
  const [initialValues, setInitialValues] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setInitialValues(data);
  }, [data]);

  function onSubmit(data, actions) {
    const newData = pick(data, ['id', 'title', 'status', 'cover', 'content']);

    save(newData)(dispatch).then(() => {
      onSubmitFinish();
    }).catch(error => {
      notification.open({
        message: i18next.t('process.register.notFoundWarning.title'),
        description: get(error, 'response.data.message', i18next.t('user.signIn.notFoundWarning.message'))
      });
    }).finally(() => {
      actions.resetForm({});
    });
  }

  function onUploadImage(file) {
    return upload({ file }).then(response => (
      Promise.resolve({ data: { link: response.data.url } })
    ));
  }

  function setStatusOptions() {
    const { unpublished, published } = Model.STATUS;

    return [
      {
        value: unpublished,
        label: i18next.t(`article.fields.statusOptions.${unpublished}`)
      },

      {
        value: published,
        label: i18next.t(`article.fields.statusOptions.${published}`)
      }
    ];
  }

  function renderForm({ handleSubmit, isSubmitting, touched, errors, setFieldValue, setFieldTouched }) {
    return (
      <form onSubmit={handleSubmit}>
        <Field
          component={Select}
          label={i18next.t('article.fields.status')}
          error={touched.status && errors.status}
          name="status"
          options={setStatusOptions()}
        />

        <Field
          component={Input}
          label={i18next.t('article.fields.title')}
          error={touched.title && errors.title}
          name="title"
        />

        <Field
          component={RichText}
          onUploadImage={onUploadImage}
          type="textarea"
          label={i18next.t('article.fields.content')}
          error={touched.content && errors.content}
          name="content"
        />

        <ButtonContainer>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            disabled={!isEmpty(errors) || isEmpty(touched) || isSubmitting}
            loading={isSubmitting}
          >
            {i18next.t('shared.actions.save')}
          </Button>
        </ButtonContainer>
      </form>
    );
  }

  return (
    <Modal
      {...otherProps}
      title={i18next.t('article.register.title')}
      width='80%'
      footer={null}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={renderForm}
        validate={validate}
      />
    </Modal>
  );
};

Register.propTypes = propTypes;
Register.defaultProps = defaultProps;

export default memo(Register);
