import Validator from 'app/utils/validator';

export default function (values) {
  const rules = {
    status: 'required|numeric',
    website: 'url',
    caseNumber: 'numeric'
  };

  return new Validator(values, rules).getErrors();
}
