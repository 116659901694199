export default {
  fields: {
    createdAt: 'Criado em',
    userName: 'Cliente',
    user: 'Cliente',
    website: 'Website',
    status: 'Status',
    caseNumber: 'Número do processo',
    casePassword: 'Senha eletrônica',
    caseLocation: 'Local do processo',
    statusOptions: {
      '0': 'Documentos pendentes',
      '1': 'Em andamento / Baixado',
      '2': 'Concluído'
    }
  },

  list: {
    title: 'Lista de processos',
    actions: 'Ações',
    addCase: 'Adicionar processo',
    deleteMessage: 'Você tem certeza que deseja deletar esse processo ?',
    openComments: 'Comentários ({{counter}})'
  },

  register: {
    title: 'Cadastro de processo',
    error: {
      title: 'Ocorreu um erro',
      message: 'Infelizmente ocorreu um erro, por favor verifique os dados e tente novamente'
    }
  }
};
