import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-top: 20px;
`;

export const FieldSection = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;

  @media screen and (max-width: 900px) {
    grid-template-columns: 100%;
  }
`;
