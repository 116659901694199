import { get } from 'lodash';
import { getSessionToken } from 'app/utils/session';
import Model from '../model';

export const ARTICLE_ADDED = 'ARTICLE_ADDED';
export const ARTICLE_EDITED = 'ARTICLE_EDITED';
export const ARTICLE_LIST = 'ARTICLE_LIST';
export const ARTICLE_DELETED = 'ARTICLE_DELETED';

export const save = data => dispatch => (
  Model.save({ data: { ...data, cover: 'null' }, authorizationToken: getSessionToken() }).then(response => {
    const payload = get(response, 'data', { status: Model.STATUS.unpublished });

    dispatch({
      type: data.id ? ARTICLE_EDITED : ARTICLE_ADDED,
      payload: { ...payload, status: Number(payload.status) }
    });
  })
);

export const list = status => dispatch => (
  Model.list(getSessionToken()).then(response => {
    dispatch({
      type: ARTICLE_LIST,
      payload: { status, data: get(response, 'data', []) }
    });
  })
);

export const getById = id => Model.getById(getSessionToken(), id);

export const deleteById = id => dispatch => (
  Model.deleteById(getSessionToken(), id).then(() => {
    dispatch({ type: ARTICLE_DELETED, payload: id });
  })
);
