import PrivateLayout from './layouts/private';
import PublicLayout from './layouts/public';
import Home from './home';
import ContactRegister from './contact/register';
import ContactList from './contact/list';
import UserSignIn from './user/signIn';
import UserRecoveryPassword from './user/recoveryPassword';
import UserValidateRecoveryPassword from './user/validateRecoveryPassword';
import UserChangePassword from './user/changePassword';
import UserRegister from './user/register';
import UserList from './user/list';
import ArticleList from './article/list';
import ArticleDetail from './article/detail';
import CaseList from './case/list';

export {
  PrivateLayout,
  PublicLayout,
  Home,
  ContactRegister,
  ContactList,
  UserSignIn,
  UserRecoveryPassword,
  UserValidateRecoveryPassword,
  UserChangePassword,
  UserRegister,
  UserList,
  ArticleList,
  ArticleDetail,
  CaseList
};
