import { get } from 'lodash';
import { getSessionToken } from 'app/utils/session';
import Model from '../model';

export const CASE_ADDED = 'CASE_ADDED';
export const CASE_EDITED = 'CASE_EDITED';
export const CASE_LIST = 'CASE_LIST';
export const CASE_DELETED = 'CASE_DELETED';

export const save = data => dispatch => (
  Model.save({ data, authorizationToken: getSessionToken() }).then(response => {
    const payload = get(response, 'data', { status: Model.STATUS.peddingDocuments });
    dispatch({ type: data.id ? CASE_EDITED : CASE_ADDED, payload });
  })
);

export const updateCommentsCounter = data => dispatch => (
  Model.updateCommentsCounter({ data, authorizationToken: getSessionToken() }).then(response => {
    const payload = get(response, 'data', { status: Model.STATUS.peddingDocuments });
    dispatch({ type: CASE_EDITED, payload });
  })
);

export const list = status => dispatch => (
  Model.list(getSessionToken()).then(response => {
    dispatch({
      type: CASE_LIST,
      payload: { status, data: get(response, 'data', []) }
    });
  })
);

export const getById = id => Model.getById(getSessionToken(), id);

export const deleteById = id => dispatch => (
  Model.deleteById(getSessionToken(), id).then(() => {
    dispatch({ type: CASE_DELETED, payload: id });
  })
);
