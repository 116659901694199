export function getSessionToken() {
  return sessionStorage.getItem('authorizationToken');
}

export function setSessionToken(token = null) {
  sessionStorage.setItem('authorizationToken', token);
}

export function removeSessionToken() {
  sessionStorage.removeItem('authorizationToken');
}
