import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Modal as ModalContainer, List } from 'antd';

const propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  zipCode: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  contactNumber: PropTypes.string
};

function Modal({ visible, onCancel, ...fields }) {
  const renderItem = useCallback(field => (
    <List.Item><b>{i18next.t(`user.fields.${field}`)}: </b>{fields[field]}</List.Item>
  ), [fields]);

  return (
    <ModalContainer
      visible={visible}
      onCancel={onCancel}
      title={i18next.t('contact.list.userModal.title')}
      footer={null}
    >
      <List
        dataSource={Object.keys(fields)}
        renderItem={renderItem}
      />
    </ModalContainer>
  )
}

Modal.propTypes = propTypes

export default memo(Modal);
