import React from 'react';
import i18next from 'i18next';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import {
  PrivateLayout,
  PublicLayout,
  Home,
  ContactRegister,
  ContactList,
  UserSignIn,
  UserRecoveryPassword,
  UserValidateRecoveryPassword,
  UserChangePassword,
  UserRegister,
  UserList,
  ArticleList,
  ArticleDetail,
  CaseList
} from '../containers';

export default () => (
  <Router>
    <Switch>
      <PublicLayout
        exact
        path="/"
        component={Home}
        title={i18next.t('home.title')}
      />

      <PublicLayout
        exact
        path="/contato"
        component={ContactRegister}
        title={i18next.t('contact.register.title')}
      />

      <PrivateLayout
        exact
        path="/contato/lista"
        component={ContactList}
        title={i18next.t('contact.list.title')}
      />

      <PublicLayout
        exact
        path="/usuario/entrar"
        component={UserSignIn}
        title={i18next.t('user.signIn.title')}
      />

      <PublicLayout
        exact
        path="/usuario/recuperar-senha"
        component={UserRecoveryPassword}
        title={i18next.t('user.recoveryPassword.title')}
      />

      <PublicLayout
        exact
        path="/usuario/validar-token-recuperacao-senha/:token"
        component={UserValidateRecoveryPassword}
        title={i18next.t('user.validateRecoveryPassword.title')}
      />

      <PrivateLayout
        exact
        path="/usuario/mudar-senha"
        component={UserChangePassword}
        title={i18next.t('user.changePassword.title')}
      />

      <PrivateLayout
        exact
        path="/usuario/cadastrar"
        component={UserRegister}
        title={i18next.t('user.signUp.title')}
      />

      <PrivateLayout
        exact
        path="/usuario/gerenciar-usuarios"
        component={UserList}
        title={i18next.t('user.list.title')}
      />

      <PrivateLayout
        exact
        path="/artigos/lista/:status?"
        component={ArticleList}
        title={i18next.t('article.list.title')}
      />

      <PublicLayout
        exact
        path="/artigo/:id"
        component={ArticleDetail}
        title={i18next.t('article.detail.title')}
      />

      <PrivateLayout
        exact
        path="/processo/lista/:status?"
        component={CaseList}
        title={i18next.t('legalCase.list.title')}
      />
    </Switch>
  </Router>
);
