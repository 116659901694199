import { combineReducers } from 'redux';
import { ARTICLE_ADDED, ARTICLE_EDITED, ARTICLE_LIST, ARTICLE_DELETED } from 'app/modules/article/actions';

function list(state = [], action = {}) {
  switch(action.type) {
    case ARTICLE_LIST:
      return action.payload.data.filter(
        item => Number(item.status) === Number(action.payload.status)
      );
    case ARTICLE_ADDED:
      return [...state, action.payload];
    case ARTICLE_EDITED:
      const newState = state.filter(item => item.id !== action.payload.id)

      if(state.map(item => Number(item.status)).includes(Number(action.payload.status))) {
        return [...newState, action.payload];
      }

      return newState;
    case ARTICLE_DELETED:
      return state.filter(item => item.id !== action.payload);
    default:
      return state;
  }
}

export default combineReducers({
  list
});
