import axios from 'axios';
import { isNull, isEmpty, isArray, has } from 'lodash';

const { REACT_APP_SERVER_BASE_URL } = process.env;

function removeNullValue(value) {
  return isNull(value) ? '' : value;
}

export const makePostResquest = ({
  route, data, authorizationToken = null, onUpload = null
}) => {
  const headers = {
    'Content-Type': 'multipart/form-data'
  };

  if(!isEmpty(authorizationToken)) {
    headers.Authorization = `Bearer ${authorizationToken}`;
  }

  const formData = new FormData();

  Object.entries(data).forEach(item => {
    const name = item[0];
    const values = item[1];

    if(isArray(values) && values.some(value => has(value, 'file'))) {
      values.forEach(value => {
        if(value.file instanceof Blob) {
          formData.append(`${name}[]`, value.file, value.file.name);
        }
      });
    } else {
      formData.append(name, removeNullValue(values));
    }
  });

  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${REACT_APP_SERVER_BASE_URL}/${route}`,
      headers,
      data: formData,
      onUploadProgress: event => onUpload && onUpload(event)
    }).then(response => {
      if(response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.status);
      }
    }).catch(error => {
      reject(error);
    });
  });
}

export const makeGetRequest = ({ route, authorizationToken = null }) => {
  const headers = {
    'Content-Type': 'application/json;charset=utf-8'
  };

  if(!isEmpty(authorizationToken)) {
    headers.Authorization = `Bearer ${authorizationToken}`;
  }

  return new Promise((resolve, reject) => {
    axios({
      headers,
      method: 'get',
      url: `${REACT_APP_SERVER_BASE_URL}/${route}`
    }).then(response => {
      if(response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.status);
      }
    }).catch(error => {
      reject(error);
    });
  });
}

export const makeDeleteRequest = ({ route, authorizationToken }) => (
  new Promise((resolve, reject) => {
    axios({
      url: `${REACT_APP_SERVER_BASE_URL}/${route}`,
      method: 'delete',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${authorizationToken}`
      }
    }).then(response => {
      if(response.status === 200) {
        resolve(response.data);
      } else {
        reject(response.status);
      }
    }).catch(error => {
      reject(error);
    });
  })
);
