import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { isEmpty, get, pick, debounce } from 'lodash';
import { Modal, Button, notification, Spin } from 'antd';
import { Formik, Field } from 'formik';
import Input from 'app/components/input';
import Select from 'app/components/select';
import Model from 'app/modules/case/model';
import { save } from 'app/modules/case/actions';
import { search as searchUsers } from 'app/modules/user/actions';
import validate from './validate';
import { ButtonContainer, FieldSection } from './styles';

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmitFinish: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    website: PropTypes.string,
    status: PropTypes.string,
    caseNumber: PropTypes.string,
    casePassword: PropTypes.string,
    caseLocation: PropTypes.string
  })
};

const defaultProps = {
  visible: false,
  data: {}
};

function Register({ history, data, onSubmitFinish, visible, ...otherProps }) {
  const [initialValues, setInitialValues] = useState({});
  const [userList, setUserList] = useState([]);
  const [isLoadingUserList, setIsLoadingUserList] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setInitialValues(data);

    if(!isEmpty(data.User) && visible) {
      setUserList([data.User]);
    } else {
      setUserList([]);
    }
  }, [data, visible]);

  function onSubmit(data, actions) {
    const newData = pick(data, ['id', 'userId', 'website', 'status', 'caseNumber', 'casePassword', 'caseLocation']);

    save(newData)(dispatch).then(() => {
      onSubmitFinish();
      actions.resetForm({});
    }).catch(error => {
      actions.setSubmitting(false);

      notification.open({
        message: i18next.t('legalCase.register.error.title'),
        description: get(error, 'response.data.message', i18next.t('legalCase.register.error.message'))
      });
    });
  }

  function setStatusOptions() {
    const { peddingDocuments, inProgress, finished } = Model.STATUS;

    return [
      {
        value: peddingDocuments,
        label: i18next.t(`legalCase.fields.statusOptions.${peddingDocuments}`)
      },

      {
        value: inProgress,
        label: i18next.t(`legalCase.fields.statusOptions.${inProgress}`)
      },

      {
        value: finished,
        label: i18next.t(`legalCase.fields.statusOptions.${finished}`)
      }
    ];
  }

  function setUserOptions() {
    return userList.map(user => ({
      label: user.name,
      value: user.id
    }));
  }

  function onUserSearch(value) {
    if(!isEmpty(value) && value.length >= 3) {
      setIsLoadingUserList(true);

      return searchUsers(value).then(({ data }) => {
        setUserList(data);
      }).finally(() => {
        setIsLoadingUserList(false);
      });
    }
  }

  function renderForm({ handleSubmit, isSubmitting, touched, errors }) {
    return (
      <form onSubmit={handleSubmit}>
        <Field
          showSearch
          defaultActiveFirstOption
          allowClear
          filterOption={false}
          component={Select}
          notFoundContent={isLoadingUserList && <Spin size="small" />}
          label={i18next.t('legalCase.fields.user')}
          error={touched.userId && errors.userId}
          name="userId"
          options={setUserOptions()}
          onSearch={debounce(onUserSearch, 500)}
        />

        <FieldSection>
          <Field
            component={Select}
            label={i18next.t('legalCase.fields.status')}
            error={touched.status && errors.status}
            name="status"
            options={setStatusOptions()}
          />

          <Field
            component={Input}
            label={i18next.t('legalCase.fields.website')}
            error={touched.website && errors.website}
            name="website"
          />
        </FieldSection>

        <FieldSection>
          <Field
            component={Input}
            label={i18next.t('legalCase.fields.caseNumber')}
            error={touched.caseNumber && errors.caseNumber}
            name="caseNumber"
          />

          <Field
            component={Input}
            label={i18next.t('legalCase.fields.casePassword')}
            error={touched.casePassword && errors.casePassword}
            name="casePassword"
          />
        </FieldSection>

        <Field
          component={Input}
          label={i18next.t('legalCase.fields.caseLocation')}
          error={touched.caseLocation && errors.caseLocation}
          name="caseLocation"
        />

        <ButtonContainer>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            disabled={!isEmpty(errors) || isEmpty(touched) || isSubmitting}
            loading={isSubmitting}
          >
            {i18next.t('shared.actions.save')}
          </Button>
        </ButtonContainer>
      </form>
    );
  }

  return (
    <Modal
      {...otherProps}
      visible={visible}
      title={i18next.t('legalCase.register.title')}
      footer={null}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={renderForm}
        validate={validate}
      />
    </Modal>
  );
};

Register.propTypes = propTypes;
Register.defaultProps = defaultProps;

export default memo(Register);
