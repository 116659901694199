import React, { memo, useState, useEffect, useCallback } from 'react';
import i18next from 'i18next';
import { get, uniqueId } from 'lodash';
import { Button, Popconfirm, Icon } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'app/utils/date';
import { list as listAll, deleteById } from 'app/modules/article/actions';
import Model from 'app/modules/article/model';
import Table from 'app/components/table';
import RegisterModal from '../register';
import { HeaderContainer, Title, ButtonContainer } from './styles';

function List({ history, routeParams }) {
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const dispatch = useDispatch();
  const list = useSelector(state => state.article.list);

  useEffect(() => {
    listAll(get(routeParams, 'status', Model.STATUS.unpublished))(dispatch);
  }, [routeParams, dispatch]);

  const onDelete = useCallback(id => () => {
    deleteById(id)(dispatch)
  }, [dispatch]);

  const toggleRegisterModal = useCallback(() => {
    setOpenRegisterModal(!openRegisterModal);
  }, [openRegisterModal]);

  const onCancelRegisterModal = useCallback(() => {
    setSelectedItem({});
    toggleRegisterModal();
  }, [toggleRegisterModal]);

  const onClickToEdit = useCallback(item => () => {
    setSelectedItem(item);
    toggleRegisterModal();
  }, [toggleRegisterModal]);

  const setDataSource = useCallback(() => {
    const filteredList = list.filter(
      item => Number(item.status) === Number(get(routeParams, 'status', Model.STATUS.unpublished))
    );

    return filteredList.map(item => ({
      ...item,
      key: uniqueId(),
      statusText: i18next.t(`article.fields.statusOptions.${item.status}`),
      createdAt: formatDate(item.createdAt)
    }));
  }, [list, routeParams]);

  const columns = [
    {
      title: i18next.t('article.fields.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt'
    },

    {
      title: i18next.t('article.fields.title'),
      dataIndex: 'title',
      key: 'title'
    },

    {
      title: i18next.t('article.list.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_, item) => (
        <ButtonContainer>
          <Button onClick={onClickToEdit(item)}>
            {i18next.t('shared.actions.edit')}
          </Button>

          <Popconfirm
            title={i18next.t('article.list.deleteMessage')}
            icon={<Icon type="exclamation-circle" />}
            okText={i18next.t('shared.decisions.yes')}
            cancelText={i18next.t('shared.decisions.no')}
            onConfirm={onDelete(item.id)}
          >
            <Button type='danger'>{i18next.t('shared.actions.delete')}</Button>
          </Popconfirm>
        </ButtonContainer>
      )
    }
  ];

  return (
    <>
      <RegisterModal
        history={history}
        visible={openRegisterModal}
        onCancel={onCancelRegisterModal}
        data={selectedItem}
        onSubmitFinish={toggleRegisterModal}
      />

      <HeaderContainer>
        <Title>{i18next.t('article.list.title')}</Title>

        <Button
          onClick={toggleRegisterModal}
          type="primary"
        >
          {i18next.t('article.list.addProcess')}
        </Button>
      </HeaderContainer>

      <Table dataSource={setDataSource()} columns={columns} />
    </>
  );
}

export default memo(List);
