import styled from 'styled-components';
import { Comment } from 'antd';

export const ListContainer = styled.div`
  margin-top: 30px;
  max-height: 300px;
  overflow-x: auto;
`;

export const CommentContainer = styled(Comment)`
  border-bottom: 1px solid #f1f1f1;

  &:last-of-type {
    border-bottom: 0px;
  }
`;
