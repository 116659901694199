import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {createStore} from 'redux';
import 'antd/dist/antd.css';
import reducers from './app/configs/reducers';
import App from './app';
import * as serviceWorker from './serviceWorker';
import './app/langs';

const store = createStore(reducers);

ReactDOM.render(
  <Provider store={store}><App /></Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
