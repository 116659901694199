export default {
  fields: {
    name: 'Nome completo',
    email: 'Email',
    password: 'Senha',
    repeatPassword: 'Repita sua senha',
    birthDate: 'Data de nascimento',
    zipCode: 'CEP',
    address: 'Endereço',
    city: 'Cidade',
    state: 'Estado',
    contactNumber: 'Número de telefone',
    firstJobDate: 'Quando foi o primeiro emprego?',
    appliedBenefitAdministratively: 'Você ja requereu o beneficio administrativamente?',
    contactType: 'Tenho interesse em contato ?',
    workForGov: 'É concursado ?',
    isWorking: 'Está trabalhando atualmente ?',
    hasLawyer: 'Já possui processo previdenciário com outro advogado?',
    lastSalary: 'Valor do último salário recebido',
    appliedBenefitAdministrativelyOptions: {
      yesButDenied: 'Sim mas fui negado',
      notAndNeedLawyer: 'Não e preciso de um advogado para me auxiliar',
      yesWithoutResponse: 'Sim mas não obtive resposta até o momento'
    },
    contactTypeOptions: {
      online: 'Online',
      presential: 'Presencial'
    }
  },

  register: {
    title: 'Contato',
    submitButton: 'Enviar mensagem'
  },

  error: {
    title: 'Ocorreu um erro',
    message: 'Infelizmente ocorreu um erro ao tentar salvar esse contato, por favor tente novamente.'
  },

  list: {
    title: 'Lista de contatos',
    fields: {
      userName: 'Nome',
      firstJobDate: '1º Emprego',
      appliedBenefitAdministratively: 'O benefício foi requerido?',
      contactType: 'Interesse em contato?',
      workForGov: 'É concursado',
      isWorking: 'Trabalha atualmente?',
      hasLawyer: 'Possui advogado?',
      lastSalary: 'Último salário',
      appliedBenefitAdministrativelyOptions: {
        0: 'Sim mas fui negado',
        1: 'Não e preciso de um advogado para me auxiliar',
        2: 'Sim mas não obtive resposta até o momento'
      },
      contactTypeOptions: {
        0: 'Online',
        1: 'Presencial'
      }
    },
    userModal: {
      title: 'Dados do usuário'
    }
  }
}