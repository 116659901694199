import { combineReducers } from 'redux';
import { CASE_ADDED, CASE_EDITED, CASE_LIST, CASE_DELETED } from 'app/modules/case/actions';

function list(state = [], action = {}) {
  switch(action.type) {
    case CASE_LIST:
      return action.payload.data.filter(
        item => item.status === action.payload.status
      );
    case CASE_ADDED:
      return [...state, action.payload];
    case CASE_EDITED:
      const newState = state.filter(item => item.id !== action.payload.id)

      if(state.map(item => Number(item.status)).includes(Number(action.payload.status))) {
        return [...newState, action.payload];
      }

      return newState;
    case CASE_DELETED:
      return state.filter(item => item.id !== action.payload);
    default:
      return state;
  }
}

export default combineReducers({
  list
});
