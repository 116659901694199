import React from 'react';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import { Card, Button, notification } from 'antd';
import { sendRecoveryPasswordToken } from 'app/modules/user/actions';
import { Formik, Field } from 'formik';
import Input from 'app/components/input';
import validate from './validate';
import { Container, Form, Link, ButtonContainer } from './styles';

export default ({ title }) => {
  function onSubmit(data, actions) {
    sendRecoveryPasswordToken(data).then(() => {
      notification.open({
        message: i18next.t('user.recoveryPassword.notification.success.title'),
        description: i18next.t('user.recoveryPassword.notification.success.message')
      });
    }).catch(() => {
      notification.open({
        message: i18next.t('user.recoveryPassword.notification.fail.title'),
        description: i18next.t('user.recoveryPassword.notification.fail.message')
      });
    }).finally(() => {
      actions.resetForm({});
    });
  }

  function renderForm({ handleSubmit, isSubmitting, touched, errors }) {
    return (
      <Form onSubmit={handleSubmit} layout="horizontal">
        <Card title={title}>
          <Field
            component={Input}
            label={i18next.t('user.fields.email')}
            error={touched.email && errors.email}
            name="email"
            onPressEnter={handleSubmit}
            type="email"
          />

          <ButtonContainer>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              disabled={!isEmpty(errors) || isEmpty(touched) || isSubmitting}
              loading={isSubmitting}
            >
              {i18next.t('user.recoveryPassword.submitButton')}
            </Button>

            <Link to="/usuario/entrar">{i18next.t('user.recoveryPassword.signInButton')}</Link>
          </ButtonContainer>
        </Card>
      </Form>
    );
  }

  return (
    <Container>
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
        render={renderForm}
        validate={validate}
      />
    </Container>
  );
};
