import { get } from 'lodash';
import { getSessionToken } from 'app/utils/session';
import Model from '../model';

export const COMMENT_ADDED = 'COMMENT_ADDED';
export const COMMENT_EDITED = 'COMMENT_EDITED';
export const COMMENT_LIST = 'COMMENT_LIST';
export const COMMENT_DELETED = 'COMMENT_DELETED';

export const save = data => dispatch => (
  Model.save({ data, authorizationToken: getSessionToken() }).then(response => {
    const payload = get(response, 'data', {});

    dispatch({
      type: data.id ? COMMENT_EDITED : COMMENT_ADDED,
      payload
    });
  })
);

export const listByRegisterId = registerId => dispatch => (
  Model.listByRegisterId(getSessionToken(), registerId).then(response => {
    dispatch({ type: COMMENT_LIST, payload: get(response, 'data', []) });
  })
);

export const getById = id => Model.getById(getSessionToken(), id);

export const deleteById = id => dispatch => (
  Model.deleteById(getSessionToken(), id).then(() => {
    dispatch({ type: COMMENT_DELETED, payload: id });
  })
);
