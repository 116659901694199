import { combineReducers } from 'redux';
import article from 'app/modules/article/reducer';
import caseReducer from 'app/modules/case/reducer';
import comment from 'app/modules/comment/reducer';

export default combineReducers({
  article,
  case: caseReducer,
  comment
});
