import styled from 'styled-components';
import { Layout, Menu, Icon } from 'antd';
import Background from 'app/assets/images/background.png';

export const Container = styled(Layout)`
  background-color: #fff;
  background-image: url(${Background});
  font-family: 'Source Sans Pro', sans-serif;
`;

export const Header = styled(Layout.Header)`
  display: flex;
  justify-content: center;
  background-color: #fff;
  height: 100px;
  position: fixed;
  left: 0px;
  right: 0px;
  z-index: 100;
  box-shadow: 0px 3px 5px -5px #000;
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media only screen and (min-width: 1140px) {
    width: 1140px;
  }
`;

export const Logo = styled.img`
  width: 100px;

  @media only screen and (max-width: 600px) {
    width: 80px !important;
    margin: 0 auto;
  }
`;

export const MenuContainer = styled(Menu)`
  border: none;

  li {
    margin-right: 5px;
    border-radius: 3px;
    transition: .5s;
    border-bottom: 0px !important;

    &:hover {
      background-color: #aa7171 !important;

      a {
        color: #fff !important;
      }
    }
  }

  .ant-menu-item-selected {
    background-color: #aa7171;

    &:hover {
      background-color: #1583e8;

      a {
        color: #fff;
      }
    }

    a {
      color: #fff;
    }
  }

  a {
    font-size: 16px;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const Content = styled(Layout.Content)`
  margin-top: 80px;
`;

export const FooterContainer = styled(Layout.Footer)`
  background-color: #aa7171 !important;
  padding-bottom: 30px;
`;

export const FooterContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 1140px) {
    width: 1140px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
  }
`;

export const FooterContactContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  a {
    margin-top: 10px;
    justify-content: center;
    color: #fff;
    font-size: 14px;

    img {
      margin-right: 10px;
    }
  }
`;

export const FooterAddressContainer = styled.div`
  display: flex;
  flex: 1;

  address {
    color: #fff;

    img {
      margin-right: 10px;
    }
  }

  @media only screen and (max-width: 600px) {
    margin-top: 10px;
  }
`;

export const MobileMenuContainer = styled.div`
  position: fixed;
  left: 0px;
  right: 20%;
  bottom: 0px;
  top: 0px;
  outline: none;
  background-color: #fff;
  box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.3);
  padding: 20px;

  a {
    padding: 10px 20px 10px 20px;
    display: block;
    font-size: 16px;
    color: #333;
    text-transform: uppercase;
  }
`;

export const MobileMenuContentContainer = styled.div`
  margin-top: 60px;
`;

export const MobileMenuToggleButton = styled(Icon)`
  position: fixed;
  z-index: 10000;
  left: 32px;
  top: 32px;
  padding: 8px;
  font-size: 20px;

  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

export const WhatsAppButton = styled.button`
  position: fixed;
  bottom: 200px;
  right: 30px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const WhatsAppImg = styled.img`
  box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 500px;
`;