import { Link as LinkLib } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 100px;
  right: 0px;
  left: 0px;
  bottom: 0px;
`;

export const Form = styled.form`
  margin-top: 50px;
  width: 400px;

  @media screen and (max-width: 1140px) {
    margin-top: 0px;
    padding: 10px;
  }
`;

export const Link = styled(LinkLib)`
  margin-left: 20px;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
`;
