import { makePostResquest, makeGetRequest, makeDeleteRequest } from 'app/apis/server';

const STATUS = {
  peddingDocuments: '0',
  inProgress: '1',
  finished: '2'
};

const save = data => makePostResquest({ route: 'case/register', ...data });
const updateCommentsCounter = data => makePostResquest({ route: 'case/update-comments-counter', ...data });
const list = authorizationToken => makeGetRequest({ route: `case/list-all`, authorizationToken });
const getById = (authorizationToken, id) => makeGetRequest({ route: `case/get/${id}`, authorizationToken });
const deleteById = (authorizationToken, id) => makeDeleteRequest({ route: `case/${id}`, authorizationToken });

export default {
  STATUS,
  save,
  updateCommentsCounter,
  list,
  getById,
  deleteById
};
