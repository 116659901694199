import React, { memo } from 'react';
import i18next from 'i18next';
import { isEmpty, get } from 'lodash';
import { Card, Button, notification } from 'antd';
import { Formik, Field } from 'formik';
import Input from 'app/components/input';
import CaseModel from 'app/modules/case/model';
import { signIn } from 'app/modules/user/actions';
import validate from './validate';
import { Container, Form, Link, ButtonContainer } from './styles';

function SignIn({ title, history }) {
  function onSubmit(data, actions) {
    signIn(data).then(() => {
      history.push(`/processo/lista/${CaseModel.STATUS.peddingDocuments}`);
    }).catch(error => {
      actions.setSubmitting(false);

      notification.open({
        message: i18next.t('user.signIn.notFoundWarning.title'),
        description: get(error, 'response.data.message', i18next.t('user.signIn.notFoundWarning.message'))
      });
    });
  }

  function renderForm({ handleSubmit, isSubmitting, touched, errors }) {
    return (
      <Form onSubmit={handleSubmit}>
        <Card title={title}>
          <Field
            component={Input}
            type="email"
            label={i18next.t('user.fields.email')}
            error={touched.email && errors.email}
            name="email"
          />

          <Field
            component={Input}
            label={i18next.t('user.fields.password')}
            error={touched.password && errors.password}
            onPressEnter={handleSubmit}
            type="password"
            name="password"
          />

          <ButtonContainer>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={!isEmpty(errors) || isEmpty(touched) || isSubmitting}
              loading={isSubmitting}
            >
              {i18next.t('user.signIn.submitButton')}
            </Button>

            <Link to="/usuario/recuperar-senha">
              {i18next.t('user.signIn.recoveryPasswordButton')}
            </Link>
          </ButtonContainer>
        </Card>
      </Form>
    );
  }

  return (
    <Container>
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
        render={renderForm}
        validate={validate}
      />
    </Container>
  );
};

export default memo(SignIn);
