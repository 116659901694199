export default {
  fields: {
    createdAt: 'Criado em',
    password: 'Senha',
    email: 'Email',
    active: 'Ativo',
    repeatPassword: 'Repita a senha',
    type: 'Tipo',
    name: 'Nome completo',
    birthDate: 'Data de nascimento',
    zipCode: 'CEP',
    address: 'Endereço',
    city: 'Cidade',
    state: 'Estado',
    contactNumber: 'Número de telefone',
    typeOptions: {
      1: 'Admin',
      2: 'Normal'
    }
  },

  signIn: {
    title: 'Autenticação',
    submitButton: 'Autenticar',
    recoveryPasswordButton: 'Recuperar senha',
    notFoundWarning: {
      title: 'Usuário não encontrado',
      message: 'Infelizmente não encontramos nenhum usuário com esses dados'
    }
  },

  register: {
    title: 'Cadastro de usuário',
    notifications: {
      fail: {
        title: 'Encontramos um problema',
        message: 'Infelizmente não conseguimos salvar os dados deste usuário'
      }
    }
  },

  recoveryPassword: {
    title: 'Recuperar senha',
    submitButton: 'Recuperar',
    signInButton: 'Fazer autenticação',
    notification: {
      success: {
        title: 'Sucesso!',
        message: 'Um email lhe foi enviado para continuar o processo, por favor acesse-o'
      },

      fail: {
        title: 'Encontramos um problema!',
        message: 'Infelizmente não foi possível recuperar a sua senha. Por favor verifique se o email inserido é o mesmo utilizado na sua conta.'
      }
    }
  },

  validateRecoveryPassword: {
    title: 'Validando recuperação de senha',
    failMessage: {
      title: 'Encontramos um problema!',
      message: 'O link utilizado está errado ou é inválido'
    }
  },

  changePassword: {
    title: 'Alterar senha',
    formTitle: 'Preencha os campos abaixo',

    notifications: {
      success: {
        title: 'Sucesso!',
        message: 'Senha alterada com sucesso!'
      },
      fail: {
        title: 'Falha',
        message: 'Não foi possível alterar a sua senha!'
      }
    }
  },

  list: {
    title: 'Lista de usuários',
    addUser: 'Adicionar usuário',
    actions: 'Ações'
  }
};
