import Validator from 'app/utils/validator';

export default function (values) {
  const rules = {
    user: {
      name: 'required',
      email: 'required|email',
      birthDate: 'required',
      zipCode: 'required',
      address: 'required',
      city: 'required',
      state: 'required',
      contactNumber: 'required'
    },

    contact: {
      firstJobDate: 'required',
      appliedBenefitAdministratively: 'required',
      contactType: 'required',
      workForGov: 'required',
      isWorking: 'required',
      hasLawyer: 'required',
      lastSalary: 'required'
    }
  };

  return new Validator(values, rules).getErrors();
}
