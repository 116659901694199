import styled from 'styled-components';

export const FieldRowContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 10px;

  @media screen and (max-width: 900px) {
    grid-template-columns: 100%;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 30px;
`;
