export default {
  fields: {
    createdAt: 'Criado em',
    title: 'Título',
    status: 'Status',
    content: 'Conteúdo',
    cover: 'Carregar capa do artigo',
    statusOptions: {
      '0': 'Não publicado',
      '1': 'Publicado'
    }
  },

  list: {
    title: 'Lista de artigos',
    actions: 'Ações',
    addProcess: 'Adicionar artigo',
    deleteMessage: 'Você tem certeza que deseja deletar esse artigo ?'
  },

  register: {
    title: 'Cadastro de artigo'
  }
};
