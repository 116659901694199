export default {
  applicationName: 'Advogada',

  nav: {
    helloUser: 'Olá, {{email}}',
    home: 'Início',
    signIn: 'Área do cliente',
    contact: 'Contato',
    contatList: 'Contatos',
    articleList: 'Artigos',
    articleListOptions: {
      unpublished: 'Não publicados',
      published: 'Publicados',
    },
    caseList: 'Processos',
    caseListOptions: {
      peddingDocuments: 'Documentos pendentes',
      inProgress: 'Em andamento / Baixado',
      finished: 'Concluído'
    },
    managerUsers: 'Gerenciar usuários',
    changePassword: 'Mudar senha',
    signOut: 'Sair'
  },

  footer: {
    tel: '(22) 99900-8876',
    email: 'beatriztripari@gmail.com',
    instagram: '@advoprevi',
    address: 'Rua Barão da Lagoa Dourada, nº 237, sala 8 - Centro (cond. Alto liceu) - Campos dos Goytacazes/RJ'
  },

  notifications: {
    error: {
      title: 'Houve um erro !',
      message: 'Infelizmente ocorreu um erro, por favor tente de novo'
    },
    fileError: {
      title: 'Error',
      message: 'Ocorreu um erro durante o carregamento do arquivo, por favor tente novamente.'
    },

    orientation: {
      title: 'Mude sua orientação!',
      message: 'Por favor use esse site em modo retrado para melhor visualização'
    }
  },

  formValidation: {
    errorNotification: 'Existem erros neste formulário. Por favor, verifique os campos em destaque.',
    errorNotificationExit: 'Existem erros neste formulário. Tem certeza que deseja continuar? As modificações não serão salvas.',
    required: 'Campo obrigatório',
    invalidEmail: 'Email inválido',
    invalidDate: 'Data inválida',
    invalidTime: 'Horário inválido',
    startDateInvalid: 'O horário inicial deve ser menor que o horário final',
    invalidDateRange: 'Intervalo inválido',
    invalidInteger: 'Digite apenas numeros inteiros',
    min: 'Este campo deve conter no mínimo :min',
    max: 'Este campo deve conter no máximo :max',
    interval: 'Os valores devem estar entre %{min} e %{max}',
    same: 'O valor deste campo deve ser igual a :attribute',
    dateInTheFuture: 'Esta data não deveria estar no futuro',
    unauthorized: 'Você não está autorizado a executar esta operação',
    size: 'Este campo deve conter :size',
    between: 'Este campo deve conter entre :min e :max',
    invalidDocumentId: 'Número de documento inválido',
    confirmed: 'O valor deste campo está diferente do próximo',
    regex: 'Formato inválido'
  },

  actions: {
    save: 'Salvar',
    add: 'Adicionar',
    back: 'Voltar',
    edit: 'Editar',
    delete: 'Excluir',
    filter: 'Filtrar'
  },

  decisions: {
    no: 'Não',
    yes: 'Sim',
    0: 'Não',
    1: 'Sim',
    disable: 'Desabilitar',
    enable: 'Habilitar'
  }
};
