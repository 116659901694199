import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Title = styled.h3``;

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 100px 100px;
  grid-column-gap: 10px;
`;
