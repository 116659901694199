import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 16px;
  color: #fff;
  padding: 20px 20px 0px 20px;

  a {
    color: #fff;
  }
`;

export const ContentContainer = styled.div`
  padding: 30px;
`;

export const LoggedUser = styled.div`
  margin: 20px 0px 10px 0px;
  padding: 10px 0px 10px 20px;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const FastCheckoutOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 10px;
    border: 1px solid #fff;
    background-color: transparent;
  }
`;

export const SubMenuTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
