import React, { useEffect } from 'react';
import { Route } from 'react-router';
import useReactRouter from 'use-react-router';
import { Link } from 'react-router-dom';
import { useOrientation } from 'react-use';
import i18next from 'i18next';
import { truncate } from 'lodash';
import { Layout, Menu, Icon, notification } from 'antd';
import UserModel from 'app/modules/user/model';
import ArticleModel from 'app/modules/article/model';
import CaseModel from 'app/modules/case/model';
import { getSession, signout, getSessionPayload } from 'app/modules/user/actions';
import { renderComponent } from '../shared';
import { Title, ContentContainer, LoggedUser, SubMenuTitleContainer } from './styles';

export default ({ component, title, ...props }) => {
  const { history } = useReactRouter();
  const { email, type } = getSessionPayload();
  const orientation = useOrientation();

  useEffect(() => {
    document.title = title;

    getSession().catch(() => {
      history.push('/');
    });
  }, [history, title]);

  useEffect(() => {
    if(orientation.type.startsWith('portrait')) {
      notification.open({
        message: i18next.t('shared.notifications.orientation.title'),
        description: i18next.t('shared.notifications.orientation.message')
      });
    }
  }, [orientation]);

  function doSignOut() {
    history.push('/');
    signout();
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Sider width={250}>
        <Title>
          <Link to="/calendar">{i18next.t('shared.applicationName')}</Link>
        </Title>

        <LoggedUser>
          {
            i18next.t('shared.nav.helloUser', {
              email: truncate(email, { 'length': 25, 'separator': '...' })
            })
          }
        </LoggedUser>

        <Menu
          theme="dark"
          defaultSelectedKeys={[props.location.pathname]}
          defaultOpenKeys={[props.location.pathname]}
          mode="inline"
          inlineIndent={15}
        >
          {
            UserModel.isAdmin(type) &&
              <Menu.Item key="/contato/lista">
                <Link to="/contato/lista">
                  <Icon type="unordered-list" />{i18next.t('shared.nav.contatList')}
                </Link>
              </Menu.Item>
          }

          <Menu.SubMenu title={
            <SubMenuTitleContainer>
              <Icon type="unordered-list" />
              <span>{i18next.t('shared.nav.caseList')}</span>
            </SubMenuTitleContainer>
          }>
            <Menu.ItemGroup title={i18next.t('shared.actions.filter')}>
              <Menu.Item key={`/processo/lista/${CaseModel.STATUS.peddingDocuments}`}>
                <Link to={`/processo/lista/${CaseModel.STATUS.peddingDocuments}`}>
                  {i18next.t('shared.nav.caseListOptions.peddingDocuments')}
                </Link>
              </Menu.Item>

              <Menu.Item key={`/processo/lista/${CaseModel.STATUS.inProgress}`}>
                <Link to={`/processo/lista/${CaseModel.STATUS.inProgress}`}>
                  {i18next.t('shared.nav.caseListOptions.inProgress')}
                </Link>
              </Menu.Item>

              <Menu.Item key={`/processo/lista/${CaseModel.STATUS.finished}`}>
                <Link to={`/processo/lista/${CaseModel.STATUS.finished}`}>
                  {i18next.t('shared.nav.caseListOptions.finished')}
                </Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu.SubMenu>

          {
            UserModel.isAdmin(type) &&
              <Menu.SubMenu title={
                <SubMenuTitleContainer>
                  <Icon type="unordered-list" />
                  <span>{i18next.t('shared.nav.articleList')}</span>
                </SubMenuTitleContainer>
              }>
                <Menu.ItemGroup title={i18next.t('shared.actions.filter')}>
                  <Menu.Item key={`/artigos/lista/${ArticleModel.STATUS.unpublished}`}>
                    <Link to={`/artigos/lista/${ArticleModel.STATUS.unpublished}`}>
                      {i18next.t('shared.nav.articleListOptions.unpublished')}
                    </Link>
                  </Menu.Item>

                  <Menu.Item key={`/artigos/lista/${ArticleModel.STATUS.published}`}>
                    <Link to={`/artigos/lista/${ArticleModel.STATUS.published}`}>
                      {i18next.t('shared.nav.articleListOptions.published')}
                    </Link>
                  </Menu.Item>
                </Menu.ItemGroup>
              </Menu.SubMenu>
          }

          {
            UserModel.isAdmin(type) &&
              <Menu.Item key="/usuario/gerenciar-usuarios">
                <Link to="/usuario/gerenciar-usuarios">
                  <Icon type="user" />{i18next.t('shared.nav.managerUsers')}
                </Link>
              </Menu.Item>
          }

          <Menu.Item key="/usuario/mudar-senha">
            <Link to="/usuario/mudar-senha">
              <Icon type="lock" />{i18next.t('shared.nav.changePassword')}
            </Link>
          </Menu.Item>

          <Menu.Item key="doSignOut" onClick={doSignOut}>
            <Icon type="logout" />{i18next.t('shared.nav.signOut')}
          </Menu.Item>
        </Menu>
      </Layout.Sider>

      <Layout>
        <Layout.Content>
          <ContentContainer>
            <Route {...props} render={renderComponent(component, props)} />
          </ContentContainer>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
