import React, { Fragment, memo, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Button, Modal } from 'antd';
import Model from 'app/modules/user/model';
import { listAll, disable, enable } from 'app/modules/user/actions';
import Table from 'app/components/table';
import Register from 'app/containers/user/register';
import { HeaderContainer, TableContainer } from './styles';

function List({ history }) {
  const [list, setList] = useState([]);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  useEffect(() => {
    listAll(Model.TYPES.normal).then(({ data }) => {
      setList(data || []);
    });
  }, []);

  function onRegisterSave(response) {
    toggleRegisterModal();

    if(response.success) {
      setList([...list, response.data]);
    }
  }

  function onEnableDisableClick({ id, deletedAt }) {
    return () => {
      const promise = deletedAt ? enable(id) : disable(id);

      promise.then(() => {
        const newList = list.reduce((acc, item) => {
          if(item.id === id) {
            acc.push({
              ...item, deletedAt: item.deletedAt ? null : new Date()
            });
          } else {
            acc.push(item);
          }

          return acc;
        }, []);

        setList(newList);
      });
    }
  }

  function toggleRegisterModal() {
    setShowRegisterModal(!showRegisterModal);
  }

  const columns = [
    {
      title: i18next.t('user.fields.email'),
      dataIndex: 'email',
      key: 'email'
    },

    {
      title: i18next.t('user.fields.name'),
      dataIndex: 'name',
      key: 'name'
    },

    {
      title: i18next.t('user.fields.active'),
      dataIndex: 'active',
      key: 'active',
      render: (text, item) => (
        <Button
          type={item.deletedAt ? 'primary' : 'danger'}
          onClick={onEnableDisableClick(item)}
        >
          {text}
        </Button>
      )
    }
  ];

  const dataSource = list.map(item => ({
    ...item,
    type: i18next.t(`user.fields.typeOptions.${item.type}`),
    active: i18next.t(`shared.decisions.${item.deletedAt ? 'enable' : 'disable'}`),
    key: item.id
  }));

  return (
    <Fragment>
      <Modal
        title={i18next.t('user.register.title')}
        visible={showRegisterModal}
        footer={null}
        onCancel={toggleRegisterModal}
      >
        <Register
          history={history}
          onSave={onRegisterSave}
          initialValues={{ type: Model.TYPES.normal }}
        />
      </Modal>

      <HeaderContainer>
        <Button type="primary" onClick={toggleRegisterModal}>
          {i18next.t('user.list.addUser')}
        </Button>
      </HeaderContainer>

      <TableContainer>
        <Table dataSource={dataSource} columns={columns} />
      </TableContainer>
    </Fragment>
  );
}

export default memo(List);
