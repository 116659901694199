import Validator from 'app/utils/validator';

export default function (values) {
  const rules = {
    email: 'required|email',
    password: 'required'
  };

  return new Validator(values, rules).getErrors();
}
