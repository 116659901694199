import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { truncate } from 'lodash';
import { Spin } from 'antd';
import HTMLToText from 'html-to-text';
import ArticleModel from 'app/modules/article/model';
import { list as listAll } from 'app/modules/article/actions';
import GridContent from 'app/components/contentContainer';
import {
  BannerContainer, BannerContent, BannerButton, ArticleContainer,
  ContentContainer, LoaderContainer, ArticleCard
} from './styles';

function Home({ history }) {
  const [loadingArticleList, setLoadingArticleList] = useState(true);

  const dispatch = useDispatch();
  const list = useSelector(state => state.article.list);

  useEffect(() => {
    listAll(ArticleModel.STATUS.published)(dispatch).finally(() => {
      setLoadingArticleList(false);
    });
  }, [dispatch]);

  const redirectToArticlePage = useCallback(id => () => {
    history.push(`/artigo/${id}`)
  }, [history]);

  const redirectToContactPage = useCallback(() => {
    history.push('contato');
  }, [history]);

  return (
    <Fragment>
      <BannerContainer>
        <BannerContent>
          <GridContent>
            <hgroup>
              <h1>{i18next.t('home.title')}</h1>
              <h2>{i18next.t('home.subtitle')}</h2>
            </hgroup>

            <BannerButton type="primary" onClick={redirectToContactPage}>
              {i18next.t('home.callToAction')}
            </BannerButton>
          </GridContent>
        </BannerContent>
      </BannerContainer>

      <ContentContainer>
        <GridContent>
          {
            loadingArticleList ?
              <LoaderContainer><Spin /></LoaderContainer>
            :
              <ArticleContainer>
                {
                  list.map(item => (
                    <ArticleCard key={item.id} title={item.title} onClick={redirectToArticlePage(item.id)}>
                      {truncate(HTMLToText.fromString(item.content), { length: 200 })}
                    </ArticleCard>
                  ))
                }
              </ArticleContainer>
          }
        </GridContent>
      </ContentContainer>
    </Fragment>
  );
}

export default memo(Home);
