import React from 'react';
import i18next from 'i18next';
import { isEmpty, get } from 'lodash';
import { Card, Button, notification } from 'antd';
import { Formik, Field } from 'formik';
import Input from 'app/components/input';
import { changePassword } from 'app/modules/user/actions';
import validate from './validate';
import { Container, Form } from './styles';

export default () => {
  function onSubmit(data, actions) {
    const message = {
      message: i18next.t('user.changePassword.notifications.success.title'),
      description: i18next.t('user.changePassword.notifications.success.message')
    };

    const { password_confirmation, ...newData } = data;

    changePassword({ ...newData, repeatPassword: password_confirmation }).catch(error => {
      message.message = i18next.t('user.changePassword.notifications.fail.title');
      message.description = get(error, 'response.data.message', i18next.t('user.changePassword.notifications.fail.message'));
    }).finally(() => {
      notification.open(message);
      actions.resetForm({});
    });
  }

  function renderForm({ handleSubmit, isSubmitting, touched, errors }) {
    return (
      <Form onSubmit={handleSubmit}>
        <Card title={i18next.t('user.changePassword.formTitle')}>
          <Field
            component={Input}
            label={i18next.t('user.fields.password')}
            error={touched.password && errors.password}
            name="password"
            type="password"
          />

          <Field
            component={Input}
            label={i18next.t('user.fields.repeatPassword')}
            name="password_confirmation"
            onPressEnter={handleSubmit}
            type="password"
          />

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            disabled={!isEmpty(errors) || isEmpty(touched) || isSubmitting}
            loading={isSubmitting}
          >
            {i18next.t('shared.actions.save')}
          </Button>
        </Card>
      </Form>
    );
  }

  return (
    <Container>
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
        render={renderForm}
        validate={validate}
      />
    </Container>
  );
};
