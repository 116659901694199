import React, { memo } from 'react';
import i18next from 'i18next';
import { isEmpty, get } from 'lodash';
import { Card, Button, notification } from 'antd';
import { Formik, Field } from 'formik';
import Select from 'app/components/select';
import Input from 'app/components/input';
import InputDate from 'app/components/inputDate';
import InputMask from 'app/components/inputMask';
import Model from 'app/modules/user/model';
import { save as saveContact } from 'app/modules/contact/actions';
import { signUp } from 'app/modules/user/actions';
import validate from './validate';
import { Container, Form, SplitFieldContainer, ButtonContainer } from './styles';

function Contact({ title, history }) {
  function onSubmit(data, actions) {
    signUp({ type: Model.TYPES.normal, ...data.user }).then(userResponse => (
      saveContact({ userId: userResponse.data.id, ...data.contact })
    )).then(() => {
      history.push('/');
    }).catch(error => {
      actions.setSubmitting(false);

      notification.open({
        message: i18next.t('contact.error.title'),
        description: get(error, 'response.data.message', i18next.t('contact.error.message'))
      });
    });
  }

  function renderForm({ handleSubmit, isSubmitting, touched, errors }) {
    return (
      <Form onSubmit={handleSubmit}>
        <Card title={title}>
          <SplitFieldContainer>
            <Field
              component={Input}
              label={i18next.t('contact.fields.name')}
              error={get(touched, 'user.name', '') && get(errors, 'user.name', '')}
              name="user[name]"
            />

            <Field
              component={Input}
              label={i18next.t('contact.fields.email')}
              error={get(touched, 'user.email', '') && get(errors, 'user.email', '')}
              type="email"
              name="user[email]"
            />
          </SplitFieldContainer>

          <SplitFieldContainer>
            <Field
              component={InputDate}
              label={i18next.t('contact.fields.birthDate')}
              error={get(touched, 'user.birthDate', '') && get(errors, 'user.birthDate', '')}
              name="user[birthDate]"
            />

            <Field
              component={InputMask}
              maskProps={{ mask: '00000{-}000' }}
              label={i18next.t('contact.fields.zipCode')}
              error={get(touched, 'user.zipCode', '') && get(errors, 'user.zipCode', '')}
              name="user[zipCode]"
            />
          </SplitFieldContainer>

          <Field
            component={Input}
            label={i18next.t('contact.fields.address')}
            error={get(touched, 'user.address', '') && get(errors, 'user.address', '')}
            name="user[address]"
          />

          <SplitFieldContainer>
            <Field
              component={Input}
              label={i18next.t('contact.fields.city')}
              error={get(touched, 'user.city', '') && get(errors, 'user.city', '')}
              name="user[city]"
            />

            <Field
              component={Input}
              label={i18next.t('contact.fields.state')}
              error={get(touched, 'user.state', '') && get(errors, 'user.state', '')}
              name="user[state]"
            />
          </SplitFieldContainer>

          <SplitFieldContainer>
            <Field
              component={InputMask}
              maskProps={{ mask: '{(}00{)}{ }00000{-}0000' }}
              label={i18next.t('contact.fields.contactNumber')}
              error={get(touched, 'user.contactNumber', '') && get(errors, 'user.contactNumber', '')}
              name="user[contactNumber]"
            />

            <Field
              component={InputDate}
              label={i18next.t('contact.fields.firstJobDate')}
              error={get(touched, 'contact.firstJobDate', '') && get(errors, 'contact.firstJobDate', '')}
              name="contact[firstJobDate]"
            />
          </SplitFieldContainer>

          <SplitFieldContainer>
            <Field
              component={Select}
              label={i18next.t('contact.fields.appliedBenefitAdministratively')}
              error={get(touched, 'contact.appliedBenefitAdministratively', '') && get(errors, 'contact.appliedBenefitAdministratively', '')}
              name="contact[appliedBenefitAdministratively]"
              options={[
                {
                  label: i18next.t('contact.fields.appliedBenefitAdministrativelyOptions.yesButDenied'),
                  value: 0
                },
                {
                  label: i18next.t('contact.fields.appliedBenefitAdministrativelyOptions.notAndNeedLawyer'),
                  value: 1
                },
                {
                  label: i18next.t('contact.fields.appliedBenefitAdministrativelyOptions.yesWithoutResponse'),
                  value: 2
                }
              ]}
            />

            <Field
              component={Select}
              label={i18next.t('contact.fields.contactType')}
              error={get(touched, 'contact.contactType', '') && get(errors, 'contact.contactType', '')}
              name="contact[contactType]"
              options={[
                {
                  label: i18next.t('contact.fields.contactTypeOptions.online'),
                  value: 0
                },
                {
                  label: i18next.t('contact.fields.contactTypeOptions.presential'),
                  value: 1
                }
              ]}
            />
          </SplitFieldContainer>

          <SplitFieldContainer>
            <Field
              component={Select}
              label={i18next.t('contact.fields.workForGov')}
              error={get(touched, 'contact.workForGov', '') && get(errors, 'contact.workForGov', '')}
              name="contact[workForGov]"
              options={[
                {
                  label: i18next.t('shared.decisions.yes'),
                  value: 1
                },
                {
                  label: i18next.t('shared.decisions.no'),
                  value: 0
                }
              ]}
            />

            <Field
              component={Select}
              label={i18next.t('contact.fields.isWorking')}
              error={get(touched, 'contact.isWorking', '') && get(errors, 'contact.isWorking', '')}
              name="contact[isWorking]"
              options={[
                {
                  label: i18next.t('shared.decisions.yes'),
                  value: 1
                },
                {
                  label: i18next.t('shared.decisions.no'),
                  value: 0
                }
              ]}
            />
          </SplitFieldContainer>

          <SplitFieldContainer>
            <Field
              component={Select}
              label={i18next.t('contact.fields.hasLawyer')}
              error={get(touched, 'contact.hasLawyer', '') && get(errors, 'contact.hasLawyer', '')}
              name="contact[hasLawyer]"
              options={[
                {
                  label: i18next.t('shared.decisions.yes'),
                  value: 1
                },
                {
                  label: i18next.t('shared.decisions.no'),
                  value: 0
                }
              ]}
            />

            <Field
              component={Input}
              label={i18next.t('contact.fields.lastSalary')}
              error={get(touched, 'contact.lastSalary', '') && get(errors, 'contact.lastSalary', '')}
              name="contact[lastSalary]"
            />
          </SplitFieldContainer>

          <ButtonContainer>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={!isEmpty(errors) || isEmpty(touched) || isSubmitting}
              loading={isSubmitting}
            >
              {i18next.t('contact.register.submitButton')}
            </Button>
          </ButtonContainer>
        </Card>
      </Form>
    );
  }

  const { REACT_APP_DEFAULT_CLIENT_USER_PASSWORD } = process.env;

  const initialValues = {
    user: {
      password: REACT_APP_DEFAULT_CLIENT_USER_PASSWORD,
      repeatPassword: REACT_APP_DEFAULT_CLIENT_USER_PASSWORD
    }
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={renderForm}
        validate={validate}
      />
    </Container>
  );
};

export default memo(Contact);
