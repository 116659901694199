import React, { memo } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { isEmpty, get } from 'lodash';
import { Button, notification } from 'antd';
import { Formik, Field } from 'formik';
import Input from 'app/components/input';
import InputDate from 'app/components/inputDate';
import InputMask from 'app/components/inputMask';
import { signUp } from 'app/modules/user/actions';
import validate from './validate';
import { FieldRowContainer, ButtonContainer } from './styles';

const propTypes = {
  initialValues: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
  onSave: PropTypes.func.isRequired
};

const defaultProps = {
  initialValues: {}
};

function Register({ history, onSave, initialValues }) {
  function onBackButtonClick() {
    history.goBack();
  }

  function onSubmit(data, actions) {
    const { password_confirmation, ...newData } = data;

    signUp({ ...newData, repeatPassword: password_confirmation }).then(onSave).catch(error => {
      notification.open({
        message: i18next.t('user.register.notifications.fail.title'),
        description: get(error, 'response.data.message', i18next.t('user.register.notifications.fail.message'))
      });

      actions.setSubmitting(false);
    });
  }

  function renderForm({ handleSubmit, isSubmitting, values, touched, errors }) {
    return (
      <form onSubmit={handleSubmit}>
        <FieldRowContainer>
          <Field
            component={Input}
            label={i18next.t('user.fields.name')}
            error={touched.name && errors.name}
            name="name"
          />

          <Field
            component={Input}
            type="email"
            label={i18next.t('user.fields.email')}
            error={touched.email && errors.email}
            name="email"
          />
        </FieldRowContainer>

        <FieldRowContainer>
          <Field
            component={InputDate}
            label={i18next.t('user.fields.birthDate')}
            error={touched.birthDate && errors.birthDate}
            name="birthDate"
          />

          <Field
            component={InputMask}
            maskProps={{ mask: '00000{-}000' }}
            label={i18next.t('user.fields.zipCode')}
            error={touched.zipCode && errors.zipCode}
            name="zipCode"
          />
        </FieldRowContainer>

        <FieldRowContainer>
          <Field
            component={Input}
            label={i18next.t('user.fields.address')}
            error={touched.address && errors.address}
            name="address"
          />

          <Field
            component={Input}
            label={i18next.t('user.fields.city')}
            error={touched.city && errors.city}
            name="city"
          />
        </FieldRowContainer>

        <FieldRowContainer>
          <Field
            component={Input}
            label={i18next.t('user.fields.state')}
            error={touched.state && errors.state}
            name="state"
          />

          <Field
            component={InputMask}
            maskProps={{ mask: '{(}00{)}{ }00000{-}0000' }}
            label={i18next.t('user.fields.contactNumber')}
            error={touched.contactNumber && errors.contactNumber}
            name="contactNumber"
          />
        </FieldRowContainer>

        {
          isEmpty(values.id) &&
            <FieldRowContainer>
              <Field
                component={Input}
                label={i18next.t('user.fields.password')}
                error={touched.password && errors.password}
                name="password"
                type="password"
              />

              <Field
                component={Input}
                label={i18next.t('user.fields.repeatPassword')}
                name="password_confirmation"
                onPressEnter={handleSubmit}
                type="password"
              />
            </FieldRowContainer>
        }

        <ButtonContainer>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            disabled={!isEmpty(errors) || isEmpty(touched) || isSubmitting}
            loading={isSubmitting}
          >
            {i18next.t('shared.actions.save')}
          </Button>

          <Button type="link" onClick={onBackButtonClick}>{i18next.t('shared.actions.back')}</Button>
        </ButtonContainer>
      </form>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={renderForm}
      validate={validate}
    />
  );
};

Register.propTypes = propTypes;
Register.defaultProps = defaultProps;

export default memo(Register);
