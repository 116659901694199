import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (max-width: 1160px) {
    width: 100%;
  }

  @media screen and (min-width: 1160px) {
    width: 1140px;
  }
`;
