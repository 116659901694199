import React, { useState, useEffect, memo, useCallback } from 'react';
import i18next from 'i18next';
import { Button } from 'antd';
import { list as listAll } from 'app/modules/contact/actions';
import { formatDate } from 'app/utils/date';
import UserModal from './userModal';
import Table from 'app/components/table';
import { TableContainer } from './styles';

function List() {
  const [list, setList] = useState([]);
  const [userModalIsVisible, setUserModalIsVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    listAll().then(({ data }) => {
      setList(data || []);
    });
  }, []);

  const toggleUserModal = useCallback(() => {
    setUserModalIsVisible(!userModalIsVisible);
  }, [userModalIsVisible]);

  const openUserModal = useCallback(record => () => {
    setSelectedUser(record.User);
    toggleUserModal();
  }, [toggleUserModal]);

  const columns = [
    {
      title: i18next.t('contact.list.fields.userName'),
      dataIndex: 'User.name',
      key: 'userName',
      render: (text, record) => <Button onClick={openUserModal(record)}>{text}</Button>
    },
    {
      title: i18next.t('contact.list.fields.firstJobDate'),
      dataIndex: 'firstJobDate',
      key: 'firstJobDate'
    },
    {
      title: i18next.t('contact.list.fields.appliedBenefitAdministratively'),
      dataIndex: 'appliedBenefitAdministratively',
      key: 'appliedBenefitAdministratively'
    },
    {
      title: i18next.t('contact.list.fields.contactType'),
      dataIndex: 'contactType',
      key: 'contactType'
    },
    {
      title: i18next.t('contact.list.fields.workForGov'),
      dataIndex: 'workForGov',
      key: 'workForGov'
    },
    {
      title: i18next.t('contact.list.fields.isWorking'),
      dataIndex: 'isWorking',
      key: 'isWorking'
    },
    {
      title: i18next.t('contact.list.fields.hasLawyer'),
      dataIndex: 'hasLawyer',
      key: 'hasLawyer'
    },
    {
      title: i18next.t('contact.list.fields.lastSalary'),
      dataIndex: 'lastSalary',
      key: 'lastSalary'
    }
  ];

  const dataSource = list.map(item => ({
    ...item,
    appliedBenefitAdministratively: i18next.t(`contact.list.fields.appliedBenefitAdministrativelyOptions.${item.appliedBenefitAdministratively}`),
    workForGov: i18next.t(`shared.decisions.${item.workForGov}`),
    hasLawyer: i18next.t(`shared.decisions.${item.hasLawyer}`),
    contactType: i18next.t(`contact.list.fields.contactTypeOptions.${item.contactType}`),
    firstJobDate: formatDate(item.firstJobDate),
    key: item.id
  }));

  return (
    <>
      <UserModal
        {...selectedUser}
        visible={userModalIsVisible}
        onCancel={toggleUserModal}
      />

      <TableContainer>
        <Table dataSource={dataSource} columns={columns} />
      </TableContainer>
    </>
  );
}

export default memo(List);
