import React, { Fragment, memo, useState, useEffect, useCallback } from 'react';
import i18next from 'i18next';
import { get, uniqueId } from 'lodash';
import { Button, Popconfirm, Icon } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'app/utils/date';
import UserModel from 'app/modules/user/model';
import { getSessionPayload } from 'app/modules/user/actions';
import { list as listAll, deleteById } from 'app/modules/case/actions';
import Model from 'app/modules/case/model';
import Table from 'app/components/table';
import CommentModal from 'app/containers/comment';
import RegisterModal from '../register';
import { HeaderContainer, Title, ButtonContainer } from './styles';

function List({ history, routeParams }) {
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const dispatch = useDispatch();
  const list = useSelector(state => state.case.list);

  const { type } = getSessionPayload();

  useEffect(() => {
    listAll(get(routeParams, 'status', Model.STATUS.peddingDocuments))(dispatch);
  }, [routeParams, dispatch]);

  const onDelete = useCallback(id => () => {
    deleteById(id)(dispatch)
  }, [dispatch]);

  const toggleRegisterModal = useCallback(() => {
    setOpenRegisterModal(!openRegisterModal);
  }, [openRegisterModal]);

  const toggleCommentModal = useCallback(() => {
    setOpenCommentModal(!openCommentModal);
  }, [openCommentModal]);

  const onCancelRegisterModal = useCallback(() => {
    setSelectedItem({});
    toggleRegisterModal();
  }, [toggleRegisterModal]);

  const onClickToEdit = useCallback(item => () => {
    setSelectedItem(item);
    toggleRegisterModal();
  }, [toggleRegisterModal]);

  const onClickToOpenCommentModal = useCallback(item => () => {
    setSelectedItem(item);
    toggleCommentModal();
  }, [toggleCommentModal]);

  const setDataSource = useCallback(() => {
    const filteredList = list.filter(
      item => item.status === get(routeParams, 'status', Model.STATUS.peddingDocuments)
    );

    return filteredList.map(item => ({
      ...item,
      key: uniqueId(),
      statusText: i18next.t(`legalCase.fields.statusOptions.${item.status}`),
      createdAt: formatDate(item.createdAt)
    }));
  }, [list, routeParams]);

  function getColumns() {
    const columns = [
      {
        title: i18next.t('legalCase.fields.createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt'
      },

      {
        title: i18next.t('legalCase.fields.status'),
        dataIndex: 'statusText',
        key: 'status'
      },

      {
        title: i18next.t('legalCase.fields.caseNumber'),
        dataIndex: 'caseNumber',
        key: 'caseNumber'
      }
    ];

    if (UserModel.isAdmin(type)) {
      columns.push({
        title: i18next.t('legalCase.fields.userName'),
        dataIndex: 'User.name',
        key: 'userName'
      });
    } else {
      columns.push(
        {
          title: i18next.t('legalCase.fields.casePassword'),
          dataIndex: 'casePassword',
          key: 'casePassword'
        },
        {
          title: i18next.t('legalCase.fields.caseLocation'),
          dataIndex: 'caseLocation',
          key: 'caseLocation'
        },
        {
          title: i18next.t('legalCase.fields.website'),
          dataIndex: 'website',
          key: 'website'
        }
      );
    }

    columns.push({
      title: i18next.t('legalCase.list.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_, item) => (
        <ButtonContainer>
          <Button onClick={onClickToOpenCommentModal(item)}>
            {i18next.t('legalCase.list.openComments', { counter: item.commentsCounter })}
          </Button>

          {
            UserModel.isAdmin(type) &&
              <Fragment>
                <Button onClick={onClickToEdit(item)}>
                  {i18next.t('shared.actions.edit')}
                </Button>

                <Popconfirm
                  title={i18next.t('legalCase.list.deleteMessage')}
                  icon={<Icon type="exclamation-circle" />}
                  okText={i18next.t('shared.decisions.yes')}
                  cancelText={i18next.t('shared.decisions.no')}
                  onConfirm={onDelete(item.id)}
                >
                  <Button type='danger'>{i18next.t('shared.actions.delete')}</Button>
                </Popconfirm>
              </Fragment>
          }
        </ButtonContainer>
      )
    });

    return columns;
  }

  return (
    <Fragment>
      <RegisterModal
        history={history}
        visible={openRegisterModal}
        onCancel={onCancelRegisterModal}
        data={selectedItem}
        onSubmitFinish={toggleRegisterModal}
      />

      <CommentModal
        registerId={get(selectedItem, 'id', 0)}
        visible={openCommentModal}
        onCancel={toggleCommentModal}
      />

      <HeaderContainer>
        <Title>{i18next.t('legalCase.list.title')}</Title>

        {
          UserModel.isAdmin(type) &&
            <Button
              onClick={toggleRegisterModal}
              type="primary"
            >
              {i18next.t('legalCase.list.addCase')}
            </Button>
        }
      </HeaderContainer>

      <Table dataSource={setDataSource()} columns={getColumns()} />
    </Fragment>
  );
}

export default memo(List);
