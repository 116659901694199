import React, { useEffect, useState } from 'react';
import { getById } from 'app/modules/article/actions';
import { Card, Spin } from 'antd';
import { Container } from './styles';

function Detail({ match: { params } }) {
  const [data, setData] = useState({
    title: '',
    content: ''
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getById(params.id).then(response => {
      setData(response.data);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [params]);

  if(isLoading) {
    return <Spin />;
  }

  return (
    <Container>
      <Card title={data.title}>
        <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
      </Card>
    </Container>
  );
}

export default Detail;
