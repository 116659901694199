import styled from 'styled-components';
import { Button, Card } from 'antd';
import BannerBackground from 'app/assets/images/banner-bg.jpg';

export const BannerContainer = styled.div`
  background-image: url(${BannerBackground});
  background-position: center;
  height: 500px;
  position: relative;
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.6);

  @media screen and (max-width: 1140px) {
    padding: 0px 30px 0px 30px;
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    margin: 0px;

    @media screen and (max-width: 1140px) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  h2 {
    font-size: 20px;
    color: #fff;
    margin: 0px;

    @media screen and (max-width: 1140px) {
      font-size: 16px;
    }
  }
`;

export const BannerButton = styled(Button)`
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0px 30px 0px 30px;
  height: 70px;
  margin-top: 40px;
  background-color: #aa7171;
  border: none;

  &:hover {
    background-color: #6b4949;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0px 50px 0px;

  @media only screen and (max-width: 600px) {
    padding: 10px 0px 10px 0px;
  }
`;

export const ArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;

  @media only screen and (max-width: 600px) {
    grid-template-columns: 100%;
    margin: 0px 10px 0px 10px;
  }
`;

export const ArticleCard = styled(Card)`
  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

