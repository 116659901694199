import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const TableContainer = styled.div`
  margin-top: 20px;
`;
