import Validator from 'app/utils/validator';

export default function (values) {
  const rules = {
    status: 'required|numeric',
    title: 'required',
    content: 'required'
  };

  return new Validator(values, rules).getErrors();
}
