import React, { Fragment, useState, useEffect, useCallback } from 'react';
import i18next from 'i18next';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { usePrevious, useWindowSize } from 'react-use';
import { slide as MobileMenu } from 'react-burger-menu';
import { Menu } from 'antd';
import WhatsAppIcon from 'app/assets/images/whatsapp.png';
import LogoFile from 'app/assets/images/logo.png';
import IconEmail from 'app/assets/images/icon-email.png';
import IconTel from 'app/assets/images/icon-tel.png';
import IconInstagram from 'app/assets/images/icon-instagram.png';
import IconLocalization from 'app/assets/images/icon-localization.png';
import { renderComponent } from '../shared';
import {
  Container,
  Header,
  ContentHeader,
  MenuContainer,
  Logo,
  Content,
  FooterContainer,
  FooterContentContainer,
  FooterContactContainer,
  FooterAddressContainer,
  MobileMenuContainer,
  MobileMenuToggleButton,
  MobileMenuContentContainer,
  WhatsAppButton,
  WhatsAppImg
} from './styles';

export default ({ component, path, ...rest }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const prevPath = usePrevious(path);
  const { width: windowWidth } = useWindowSize();

  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if((prevPath !== path) && isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  }, [path, prevPath, toggleMobileMenu, isMobileMenuOpen]);

  const onWhatsAppButtonClicks = () => {
    window.open('https://wa.me/5522999008876');
  };

  const links = {
    home: <Link to="/">{i18next.t('shared.nav.home')}</Link>,
    signIn: <Link to="/usuario/entrar">{i18next.t('shared.nav.signIn')}</Link>,
    contact: <Link to="/contato">{i18next.t('shared.nav.contact')}</Link>
  };

  return (
    <Fragment>
      <MobileMenuToggleButton type="menu" onClick={toggleMobileMenu} />

      {
        windowWidth <= 600 && (
          <MobileMenu
            noOverlay
            isOpen={isMobileMenuOpen}
            width={'100%'}
          >
            <MobileMenuContainer>
              <MobileMenuContentContainer>
                {links.home}
                {links.signIn}
                {links.contact}
              </MobileMenuContentContainer>
            </MobileMenuContainer>
          </MobileMenu>
        )
      }

      <Container style={{ minHeight: '100vh' }}>
        <Header>
          <ContentHeader>
            <Logo
              src={LogoFile}
              alt={i18next.t('shared.applicationName')}
            />

            <MenuContainer mode="horizontal" defaultSelectedKeys={[path]}>
              <Menu.Item key="/">{links.home}</Menu.Item>
              <Menu.Item key="/usuario/entrar">{links.signIn}</Menu.Item>
              <Menu.Item key="/contato">{links.contact}</Menu.Item>
            </MenuContainer>
          </ContentHeader>
        </Header>

        <Content>
          <Route {...rest} render={renderComponent(component, rest)} />
        </Content>

        <FooterContainer>
          <FooterContentContainer>
            <FooterContactContainer>
              <a href={`tel:+55${i18next.t('shared.footer.tel')}`} target="_blank" rel="noopener noreferrer">
                <img src={IconTel} alt={i18next.t('shared.footer.tel')}/>
                {i18next.t('shared.footer.tel')}
              </a>
              <a href={`mailto:${i18next.t('shared.footer.email')}`} target="_blank" rel="noopener noreferrer">
                <img src={IconEmail} alt={i18next.t('shared.footer.email')}/>
                {i18next.t('shared.footer.email')}
              </a>

              <a href={`https://instagram.com/${i18next.t('shared.footer.instagram')}`} target="_blank" rel="noopener noreferrer">
                <img src={IconInstagram} alt={i18next.t('shared.footer.instagram')}/>
                {i18next.t('shared.footer.instagram')}
              </a>
            </FooterContactContainer>

            <FooterAddressContainer>
              <address>
                <img src={IconLocalization} alt={i18next.t('shared.footer.address')}/>
                {i18next.t('shared.footer.address')}
              </address>
            </FooterAddressContainer>
          </FooterContentContainer>
        </FooterContainer>
      </Container>

      <WhatsAppButton onClick={onWhatsAppButtonClicks}>
        <WhatsAppImg src={WhatsAppIcon} width={50} height={50} />
      </WhatsAppButton>
    </Fragment>
  );
};
