import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;

  @media only screen and (max-width: 600px) {
    padding: 10px;
  }

  @media only screen and (min-width: 1140px) {
    width: 1140px;
    margin: 0 auto;
    margin-top: 50px;
  }
`;
