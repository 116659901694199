import { makePostResquest, makeGetRequest, makeDeleteRequest } from 'app/apis/server';

const save = data => makePostResquest({ route: 'comment/register', ...data });
const listByRegisterId = (authorizationToken, registerId) => (
  makeGetRequest({ route: `comment/list-all/${registerId}`, authorizationToken })
);
const getById = (authorizationToken, id) => makeGetRequest({ route: `comment/get/${id}`, authorizationToken });
const deleteById = (authorizationToken, id) => makeDeleteRequest({ route: `comment/${id}`, authorizationToken });

export default {
  save,
  listByRegisterId,
  getById,
  deleteById
};
