import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const propTypes = {
  form: PropTypes.instanceOf(Object).isRequired,
  field: PropTypes.instanceOf(Object).isRequired,
  onUploadImage: PropTypes.func.isRequired
};

function RichText({ onUploadImage, form, field }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    const { value } = field;

    if (isEmpty(value)) {
      setEditorState(EditorState.createEmpty());
    } else {
      const contentBlock = htmlToDraft(value);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);

        setEditorState(editorState);
      }
    }
  }, [field]);

  function onChange(value) {
    setEditorState(value);
  };

  function onBlur() {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    form.setFieldValue(field.name, html);
    form.setFieldTouched(field.name);
  }

  const toolbarProps = {
    options: [
      'history',
      'inline',
      'blockType',
      'fontSize',
      'fontFamily',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'embedded',
      'emoji',
      'remove',
      'image'
    ],
    image: {
      urlEnabled: false,
      uploadEnabled: true,
      alignmentEnabled: false,
      uploadCallback: onUploadImage,
      previewImage: true,
      inputAccept: 'image/jpeg,image/jpg,image/png',
      defaultSize: {
        height: 'auto',
        width: 'auto',
      }
    }
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onChange}
      toolbar={toolbarProps}
      onBlur={onBlur}
      locale='pt'
      editorStyle={{ minHeight: '300px' }}
    />
  );
}

RichText.propTypes = propTypes;

export default RichText;
